import * as models from '../../domain/models';

import * as actions from '../actions/offer-list';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  criteria: viewModels.OffersLookupCriteria;
  skip: number;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.OperatorPurchaseOffer>;
  itemsPerPage: number;
  itemsTotalCount: number;
  selectedPurchaseOffer: models.OperatorPurchaseOffer;
  isSelectedPurchaseOfferLoading: boolean;
  isSelectedPurchaseOfferLoaded: boolean;
  acceptOfferDialogVisible: boolean;
  competitorOffers: Array<models.CompetitorOffer>;
  isCompetitorOffersLoading: boolean;
  yourOffer: models.CompetitorOffer;
  legs: Array<models.LegRequest>;
}

const initialState: State = {
  criteria: new viewModels.OffersLookupCriteria(),
  skip: 1,
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 20,
  itemsTotalCount: 0,
  selectedPurchaseOffer: new models.OperatorPurchaseOffer(),
  isSelectedPurchaseOfferLoading: false,
  isSelectedPurchaseOfferLoaded: false,
  acceptOfferDialogVisible: false,
  competitorOffers: null,
  isCompetitorOffersLoading: false,
  yourOffer: null,
  legs: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET : {
      return {...initialState};
    }

    case actions.SEARCH: {
      return {
        ...state,
        criteria: _.cloneDeep(action.payload.criteria),
        itemsPerPage: action.payload.itemsPerPage,
        isLoading: action.payload.isLoading === false ? action.payload.isLoading : true
      };
    }

    case actions.SEARCH_SUCCESS: {
      return {
        ...state,
        skip: action.payload.skip,
        isLoaded: true,
        isLoading: false,
        items: action.payload.data,
        itemsTotalCount: action.payload.count
      };
    }

    case actions.SET_SELECTED_PURCHASE_OFFER: {
      return {
        ...state,
        selectedPurchaseOffer: _.cloneDeep(action.payload)
      };
    }

    case actions.GET_NEW_PURCHASE_OFFER_BY_SOURCING_REQUEST: 
    case actions.GET_PURCHASE_OFFER: {
      return {
        ...state,
        isSelectedPurchaseOfferLoading: true,
      };
    }

    case actions.GET_PURCHASE_OFFER_SUCCESS: {
      return {
        ...state,
        isSelectedPurchaseOfferLoaded: true,
        isSelectedPurchaseOfferLoading: false
      };
    }

    case actions.GET_PURCHASE_OFFER_FAIL: {
      return {
        ...state,
        isSelectedPurchaseOfferLoaded: false,
        isSelectedPurchaseOfferLoading: false
      };
    }

    case actions.SET_ACCEPT_OFFER_DIALOG_VISIBLE: {
      return {
        ...state,
        acceptOfferDialogVisible: action.payload
      };
    }

    case actions.SET_YOUR_OFFER_AIRCRAFT:
    case actions.SET_YOUR_OFFER_PRICE: {
      return {
        ...state,
        yourOffer: {...state.yourOffer, ...action.payload}
      };
    }

    case actions.SET_FBOS: {
      return {
        ...state,
        legs: action.payload
      };
    }

    case actions.ADD_NEW_LEG: {
      const legs: Array<models.LegRequest> = state.legs?.length ? _.cloneDeep(state.legs) : [];
      legs.push(new models.LegRequest);
      const selectedPurchaseOffer: models.OperatorPurchaseOffer = _.cloneDeep(state.selectedPurchaseOffer);
      selectedPurchaseOffer.setLegs(legs);
      return {
        ...state,
        legs,
        selectedPurchaseOffer
      }
    }

    case actions.REMOVE_LEG: {
      const legs: Array<models.LegRequest> = state.legs?.length ? _.cloneDeep(state.legs) : [];
      const filteredLegs = legs.reduce((acc, item, index) => {
        if (index !== action.payload) 
        {
          acc.push(item);
        }
        return acc;
      }, []);
      const selectedPurchaseOffer: models.OperatorPurchaseOffer = _.cloneDeep(state.selectedPurchaseOffer);
      selectedPurchaseOffer.setLegs(filteredLegs);
      return {
        ...state,
        legs: filteredLegs,
        selectedPurchaseOffer
      }
    }

    default: {
      return state;
    }
  }
}

export const getCriteria = (state: State) => state.criteria;
export const getSkip = (state: State) => state.skip;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
export const getSelectedPurchaseOffer = (state: State) => state.selectedPurchaseOffer;
export const getIsSelectedPurchaseOfferLoading = (state: State) => state.isSelectedPurchaseOfferLoading;
export const getIsSelectedPurchaseOfferLoaded = (state: State) => state.isSelectedPurchaseOfferLoaded;
export const getAcceptOfferDialogVisible = (state: State) => state.acceptOfferDialogVisible;
export const getYourOffer = (state: State) => {
  return (state.yourOffer && state.yourOffer.offerPrice && state.yourOffer.categoryName) ? [state.yourOffer] : null;
};
export const getLegs = (state: State) => state.legs;
