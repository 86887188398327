import { Action } from '@ngrx/store';
import { AircraftSortingInput } from '../../../../shared/graphql-types';
import * as models from '../../domain/models';

export const LOAD_AIRCRAFT_LIST = '[Fleet Aircraft List] Load Aircraft List';
export const LOAD_AIRCRAFT_LIST_SUCCESS = '[Fleet Aircraft List] Load Aircraft List Success';
export const LOAD_AIRCRAFT_LIST_FAIL = '[Fleet Aircraft List] Load Aircraft List Fail';
export const CHANGE_PAGE_AIRCRAFT_LIST_ACTION = '[Fleet Aircraft List] Change page action for Aircraft List';
export const CHANGE_SORTING_AIRCRAFT_LIST_ACTION = '[Fleet Aircraft List] Change sorting action for Aircraft List';

export class LoadAircraftListSuccessPayload {
  data: Array<models.Aircraft>;
  count: number;
}

export class LoadAircraftListActionPayload {
  fetchNetworkOnly: boolean = false;
}

export class LoadAircraftListAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST;
  
  constructor(public payload: LoadAircraftListActionPayload = new LoadAircraftListActionPayload()) {}
}

export class LoadAircraftListSuccessAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_SUCCESS;

  constructor(public payload: LoadAircraftListSuccessPayload) {
  }
}

export class LoadAircraftListFailAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_FAIL;
}

export class ChangePageAircraftListAction implements Action {
  readonly type = CHANGE_PAGE_AIRCRAFT_LIST_ACTION;

  constructor(public payload: { skip: number, take: number }) {
  }
}

export class ChangeSortingAircraftListAction implements Action {
  readonly type = CHANGE_SORTING_AIRCRAFT_LIST_ACTION;

  constructor(public payload: AircraftSortingInput) {
  }
}

export type Actions = LoadAircraftListAction
  | LoadAircraftListSuccessAction
  | LoadAircraftListFailAction
  | ChangePageAircraftListAction
  | ChangeSortingAircraftListAction;
