import { InjectionToken } from '@angular/core';
import {Observable} from 'rxjs/Observable';

export interface IOperatorService {
  eulaPdfUrl: string;
  operatorEulaPdfUrl: string;
  acceptUserAgreement(): Observable<void>;
  acceptOperatorAgreement(request: AcceptOperatorAgreementRequest): Observable<void>;
  getUserAgreement(): Observable<GetUserAgreementResponse>;
  getAgreementPdf(request: GetAgreementPdfRequest): Observable<Blob>;
  downloadAgreementPdf(request: DownloadAgreementPdfRequest): Observable<DownloadUserAgreementPdfResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Core.IOperatorService');

export class AcceptUserAgreementRequest {
  agreementAcceptUrl: string;
}

export class AcceptUserAgreementResponse {
  isAccepted: boolean;
}

export class GetAgreementPdfRequest {
  url: string;
}

export class GetUserAgreementPdfResponse {
}

export class DownloadAgreementPdfRequest {
  url: string;
}

export class DownloadUserAgreementPdfResponse {
}

export class GetUserAgreementRequest {
}

export class GetUserAgreementResponse {
  userEulaAccepted: boolean;
  operatorEulaAccepted: boolean;
  superuser: boolean;
  operatorUuid: string;
}

export class AcceptOperatorAgreementRequest {
  operatorUuid: string;
}
