import { User } from './user';
import { AircraftCategory } from './aircraft-category';
import { LegRequest } from './leg-request';
import { Operator } from './operator';
import { DBA } from '../../../../core/models/dba-enum';
import { OfferTypeEnum } from './offer-type-enum';
import { OfferStatusEnum } from './offer-status-enum';
import { BidHistoryItem } from './bid-history-item';
import { CompetitorOffer } from './competitor-offer';
import { CurrencyEnum } from './currency-enum';
import { EfficiencyCosts } from './efficiency-costs';
import { RequestStatusEnum } from './request-status-enum';
import { SourcingRequest } from './sourcing-request';

export class OperatorPurchaseOffer {
  sourcingRequestId: string;
  sourcingRequest: SourcingRequest;
  offerPrice: number = null;
  flightPrice: number;
  commission: number;
  operatorPrice: number;
  createdUser: User;
  offerCount = 0;
  aircraftCategories: Array<AircraftCategory>;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  createdDate: Date;
  expirationDate: Date;
  legs: Array<LegRequest>;
  purchaseOfferId: string;
  operatorPurchaseOfferId: string;
  operator: Operator;
  status: OfferStatusEnum;
  dba: DBA;
  acceptedDate?: Date;
  acceptedByUser?: User;
  aircraftId: number;
  priceType: OfferTypeEnum;
  bidsHistory?: Array<BidHistoryItem>;
  modelName: string;
  categoryName: string;
  categoryId: number;
  tailNumber: string;
  competitorInsights: Array<CompetitorOffer>;
  specialRequest?: string;
  quoteReferenceId?: string;
  petOnBoard?: boolean;
  petNote?: string;
  ownerAircraftApprovalAcquired?: boolean;
  flightRequestId: number;
  offerCurrency: CurrencyEnum;
  offerPriceInCurrency: number;
  firmFlexDeparture: boolean;
  firmFlexDepartureFee: number;
  travelReason: string;
  specialRequirements: string;
  efficiencyCosts: EfficiencyCosts;

  getDba(): DBA {
    return this.dba;
  }

  setDba(dba: DBA): OperatorPurchaseOffer {
    this.dba = dba;
    return this;
  }

  getPurchaseOfferId(): string {
    return this.purchaseOfferId;
  }

  setPurchaseOfferId(purchaseOfferId: string): OperatorPurchaseOffer {
    this.purchaseOfferId = purchaseOfferId;
    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): OperatorPurchaseOffer {
    this.operator = operator;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(expirationDate: Date): OperatorPurchaseOffer {
    this.expirationDate = expirationDate;
    return this;
  }

  getOperatorPurchaseOfferId(): string {
    return this.operatorPurchaseOfferId;
  }

  setOperatorPurchaseOfferId(operatorPurchaseOfferId: string): OperatorPurchaseOffer {
    this.operatorPurchaseOfferId = operatorPurchaseOfferId;
    return this;
  }

  getSourcingRequestId(): string {
    return this.sourcingRequestId;
  }

  setSourcingRequestId(sourcingRequestId: string): OperatorPurchaseOffer {
    this.sourcingRequestId = sourcingRequestId;
    return this;
  }

  getSourcingRequest(): SourcingRequest {
    return this.sourcingRequest;
  }

  setSourcingRequest(status: SourcingRequest): OperatorPurchaseOffer {
    this.sourcingRequest = status;
    return this;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  getAircraftCategoriesNames(): string {
    if (!this.aircraftCategories || !this.aircraftCategories.length) {
      return '';
    }
    return this.aircraftCategories.reduce((res, category) => `${res}${res ? ', ' : ''}${category.getName()}`, '');
  }

  setAircraftCategories(aircraftCategories: Array<AircraftCategory>): OperatorPurchaseOffer {
    this.aircraftCategories = aircraftCategories;

    return this;
  }

  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): OperatorPurchaseOffer {
    this.flightPrice = flightPrice;
    return this;
  }

  getCommission(): number {
    return this.commission;
  }

  setCommission(commission: number): OperatorPurchaseOffer {
    this.commission = commission;
    return this;
  }

  getOperatorPrice(): number {
    return this.operatorPrice;
  }

  setOperatorPrice(operatorPrice: number): OperatorPurchaseOffer {
    this.operatorPrice = operatorPrice;
    return this;
  }

  getOfferPrice(): number {
    return this.offerPrice;
  }

  setOfferPrice(maxPrice: number): OperatorPurchaseOffer {
    this.offerPrice = maxPrice;
    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): OperatorPurchaseOffer {
    this.legs = legs;
    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): OperatorPurchaseOffer {
    this.lastModifiedDate = lastModifiedDate;
    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): OperatorPurchaseOffer {
    this.lastModifiedUser = lastModifiedUser;
    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): OperatorPurchaseOffer {
    this.createdDate = createdDate;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): OperatorPurchaseOffer {
    this.createdUser = createdUser;
    return this;
  }

  getStatus(): OfferStatusEnum {
    return this.status;
  }

  setStatus(status: OfferStatusEnum): OperatorPurchaseOffer {
    this.status = status;
    return this;
  }

  getOfferCount(): number {
    return this.offerCount;
   }

  setOfferCount(offerCount: number): OperatorPurchaseOffer {
    this.offerCount = offerCount;
    return this;
  }

  getAcceptedDate(): Date {
    return this.acceptedDate;
  }

  setAcceptedDate(acceptedDate: Date): OperatorPurchaseOffer {
    this.acceptedDate = acceptedDate;
    return this;
  }

  getAcceptedByUser(): User {
    return this.acceptedByUser;
  }

  setAcceptedByUser(acceptedByUser: User): OperatorPurchaseOffer {
    this.acceptedByUser = acceptedByUser;
    return this;
  }

  getAircraftId(): number {
    return this.aircraftId;
  }

  setAircraftId(aircraftId: number): OperatorPurchaseOffer {
    this.aircraftId = aircraftId;
    return this;
  }

  getPriceType(): OfferTypeEnum {
    return this.priceType;
  }

  setPriceType(type: OfferTypeEnum): OperatorPurchaseOffer {
    this.priceType = type;
    return this;
  }

  setBidsHistory(bids: Array<BidHistoryItem>): OperatorPurchaseOffer {
    this.bidsHistory = bids;
    return this;
  }

  getBidsHistory(): Array<BidHistoryItem> {
    return this.bidsHistory;
  }

  getModelName(): string {
    return this.modelName;
  }

  setModelName(modelName: string): OperatorPurchaseOffer {
    this.modelName = modelName;
    return this;
  }

  getCategoryName(): string {
    return this.categoryName;
  }

  setCategoryName(categoryName: string): OperatorPurchaseOffer {
    this.categoryName = categoryName;
    return this;
  }

  getCategoryId(): number {
    return this.categoryId;
  }

  setCategoryId(categoryId: number): OperatorPurchaseOffer {
    this.categoryId = categoryId;
    
    return this;
  }

  getTailNumber(): string {
    return this.tailNumber;
  }

  setTailNumber(tailNumber: string): OperatorPurchaseOffer {
    this.tailNumber = tailNumber;
    return this;
  }

  getCompetitorInsights(): Array<CompetitorOffer> {
    return this.competitorInsights;
  }

  setCompetitorInsights(competitorInsights: Array<CompetitorOffer>): OperatorPurchaseOffer {
    this.competitorInsights = competitorInsights;
    return this;
  }

  getSpecialRequest(): string {
    return this.specialRequest;
  }

  setSpecialRequest(specialRequest: string): OperatorPurchaseOffer {
    this.specialRequest = specialRequest;
    return this;
  }

  getQuoteReferenceId(): string {
    return this.quoteReferenceId;
  }

  setQuoteReferenceId(quoteReferenceId: string): OperatorPurchaseOffer {
    this.quoteReferenceId = quoteReferenceId;
    return this;
  }

  getPetOnBoard(): boolean {
    return this.petOnBoard;
  }

  setPetOnBoard(petOnBoard: boolean): OperatorPurchaseOffer {
    this.petOnBoard = petOnBoard;

    return this;
  }

  getPetNote(): string {
    return this.petNote;
  }

  setPetNote(petNote: string): OperatorPurchaseOffer {
    this.petNote = petNote;

    return this;
  }

  getOwnerAircraftApprovalAcquired(): boolean {
    return this.ownerAircraftApprovalAcquired;
  }

  setOwnerAircraftApprovalAcquired(ownerAircraftApprovalAcquired: boolean): OperatorPurchaseOffer {
    this.ownerAircraftApprovalAcquired = ownerAircraftApprovalAcquired;

    return this;
  }

  getFlightRequestId(): number {
    return this.flightRequestId;
  }

  setFlightRequestId(flightRequestId: number): OperatorPurchaseOffer {
    this.flightRequestId = flightRequestId;
    return this;
  }

  getOfferCurrency(): CurrencyEnum {
    return this.offerCurrency;
  }

  setOfferCurrency(currency: string): OperatorPurchaseOffer {
    this.offerCurrency = CurrencyEnum[currency];
    return this;
  }

  getOfferPriceInCurrency(): number {
    return this.offerPriceInCurrency;
  }

  setOfferPriceInCurrency(price: number): OperatorPurchaseOffer {
    this.offerPriceInCurrency = price;
    return this;
  }

  getTravelReason(): string {
    return this.travelReason;
  }

  setTravelReason(travelReason: string): OperatorPurchaseOffer {
    this.travelReason = travelReason;

    return this;
  }

  getSpecialRequirements(): string {
    return this.specialRequirements;
  }

  setSpecialRequirements(specialRequirements: string): OperatorPurchaseOffer {
    this.specialRequirements = specialRequirements;

    return this;
  }

  getFirmFlexDeparture(): boolean {
    return this.firmFlexDeparture;
  }

  setFirmFlexDeparture(firmFlexDeparture: boolean): OperatorPurchaseOffer {
    this.firmFlexDeparture = firmFlexDeparture;
    return this;
  }

  getFirmFlexDepartureFee(): number {
    return this.firmFlexDepartureFee;
  }

  setFirmFlexDepartureFee(firmFlexDepartureFee: number): OperatorPurchaseOffer {
    this.firmFlexDepartureFee = firmFlexDepartureFee;
    return this;
  }

  getEfficiencyCosts(): EfficiencyCosts {
    return this.efficiencyCosts;
  }

  setEfficiencyCosts(efficiencyCosts: EfficiencyCosts): OperatorPurchaseOffer {
    this.efficiencyCosts = efficiencyCosts;

    return this;
  }
}
