import * as actions from '../actions/aircraft-list';
import * as models from '../../domain/models';
import * as _ from 'lodash';
import { AircraftSortingInput } from '../../../../shared/graphql-types';

export interface State {
  sorting: AircraftSortingInput;
  skip: number;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Aircraft>;
  itemsPerPage: number;
  itemsTotalCount: number;
  search: string;
}

const initialState: State = {
  sorting: { tailNumber: true },
  skip: 0,
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 20,
  itemsTotalCount: 0,
  search: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_AIRCRAFT_LIST: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }

    case actions.LOAD_AIRCRAFT_LIST_SUCCESS: {
      const payload: actions.LoadAircraftListSuccessPayload = action.payload;

      return Object.assign({}, state, {
        itemsTotalCount: payload.count,
        items: payload.data,
        isLoaded: true,
        isLoading: false
      });
    }

    case actions.LOAD_AIRCRAFT_LIST_FAIL: {

      return Object.assign({}, state, {
        itemsTotalCount: 0,
        items: [],
        isLoaded: true,
        isLoading: false
      });
    }

    case actions.CHANGE_PAGE_AIRCRAFT_LIST_ACTION: {

      return Object.assign({}, state, {
        skip: action.payload.skip,
        itemsPerPage: action.payload.take
      });
    }

    case actions.CHANGE_SORTING_AIRCRAFT_LIST_ACTION: {

      return Object.assign({}, state, {
        sorting: action.payload,
      });
    }

    default: {
      return state;
    }
  }
}

export const getSkip = (state: State) => state.skip;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
export const getSorting = (state: State) => state.sorting;
export const getSearch = (state: State) => state.search;
