import * as fromAircraftList from './aircraft-list';
import * as fromAircraftDetails from './aircraft-details';
import * as fromAmenityCollection from './amenity-collection';
import * as fromEquipmentCollection from './equipment-collection';
import { ActionReducer, ActionReducerMap, combineReducers, createSelector } from '@ngrx/store';

export interface State {
  aircraftList: fromAircraftList.State;
  aircraftDetails: fromAircraftDetails.State;
  amenityCollection: fromAmenityCollection.State;
  equipmentCollection: fromEquipmentCollection.State;
}

const reducers: ActionReducerMap<State> = {
  aircraftList: fromAircraftList.reducer,
  aircraftDetails: fromAircraftDetails.reducer,
  amenityCollection: fromAmenityCollection.reducer,
  equipmentCollection: fromEquipmentCollection.reducer,
};

const combinedReducers: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  return combinedReducers(state, action);
}

export const getAircraftListState = state => state.fleet.aircraftList;
export const getAircraftListItems = createSelector(getAircraftListState, fromAircraftList.getItems);
export const getAircraftListLoaded = createSelector(getAircraftListState, fromAircraftList.getIsLoaded);
export const getAircraftListLoading = createSelector(getAircraftListState, fromAircraftList.getIsLoading);
export const getAircraftListPerPage = createSelector(getAircraftListState, fromAircraftList.getItemsPerPage);
export const getAircraftListTotalCount = createSelector(getAircraftListState, fromAircraftList.getItemsTotalCount);
export const getAircraftListSkip = createSelector(getAircraftListState, fromAircraftList.getSkip);
export const getAircraftListSorting = createSelector(getAircraftListState, fromAircraftList.getSorting);

export const getAircraftDetailsState = state => state.fleet.aircraftDetails;
export const getAircraftDetails = createSelector(getAircraftDetailsState, fromAircraftDetails.getAircraftDetails);
export const getAircraftDetailsIsLoaded = createSelector(getAircraftDetailsState, fromAircraftDetails.getAircraftDetailsIsLoaded);
export const getAircraftDetailsIsLoading = createSelector(getAircraftDetailsState, fromAircraftDetails.getAircraftDetailsIsLoading);
export const getAircraftDetailsIsPristine = createSelector(getAircraftDetailsState, fromAircraftDetails.getPristine);
export const getAircraftDetailsEditMode = createSelector(getAircraftDetailsState, fromAircraftDetails.getEditMode);
export const getAircraftDetailsEditActionNew = createSelector(getAircraftDetailsState, fromAircraftDetails.getEditActionNew);

export const getAmenityCollectionState = (state: any) => state.fleet.amenityCollection;
export const getAmenityCollectionIsLoaded = createSelector(getAmenityCollectionState, fromAmenityCollection.getIsLoaded);
export const getAmenityCollectionIsLoading = createSelector(getAmenityCollectionState, fromAmenityCollection.getIsLoading);
export const getAmenityCollectionItems = createSelector(getAmenityCollectionState, fromAmenityCollection.getItems);

export const getEquipmentCollectionState = (state: any) => state.fleet.equipmentCollection;
export const getEquipmentCollectionIsLoaded = createSelector(getEquipmentCollectionState, fromEquipmentCollection.getIsLoaded);
export const getEquipmentCollectionIsLoading = createSelector(getEquipmentCollectionState, fromEquipmentCollection.getIsLoading);
export const getEquipmentCollectionItems = createSelector(getEquipmentCollectionState, fromEquipmentCollection.getItems);