import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { PagesModule } from '../../assets/pages/components/pages.module';
import { pgTabsModule } from '../../assets/pages/components/tabs/tabs.module';

import * as moduleComponents from './components';
import * as moduleContainers from './containers';
import * as moduleEffects from './effects';
import * as moduleGuards from './guards';
import * as moduleServices from './services';
import * as operatorServiceContract from './services/operator/contracts/operator';
import * as moduleDirectives from './directives';

import { environment } from '../../environments/environment';
import { EnvironmentEnum } from '../../environments/environment.interface';
import { SharedModule } from '../shared/shared.module';
import { LayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
  declarations: [
    moduleComponents.ComingSoonComponent,
    moduleComponents.AppComponent,
    moduleComponents.HeaderComponent,
    moduleComponents.AppSidebarComponent,
    moduleComponents.PdfComponent,
    moduleComponents.UserAgreementComponent,
    moduleComponents.FooterComponent,
    moduleComponents.ConfirmationDialogComponent,
    moduleComponents.WarningDialogComponent,
    moduleComponents.PdfComponent,
    moduleComponents.TermsAndConditionsComponent,
    moduleContainers.LandingComponent,
    moduleContainers.AccessDeniedPageComponent,
    moduleContainers.NotFoundPageComponent,
    moduleContainers.UserAgreementPageComponent,
    moduleContainers.DebugComponent,
    moduleContainers.ErrorPageComponent,
    moduleContainers.StatusPageComponent,
    moduleDirectives.ClickOutsideDirective
  ],
  imports: [
    BsDropdownModule.forRoot(),
    DropDownsModule,
    CommonModule,
    FormsModule,
    PagesModule,
    pgTabsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    LayoutModule,
    EffectsModule.forFeature([
      moduleEffects.UserEffects,
      moduleEffects.ApiStatusEffects,
    ])
  ],
  providers: [
    moduleGuards.UserAgreementGuard,
    moduleGuards.UserAgreementAcceptedGuard,
    {
      provide: operatorServiceContract.SERVICE_TOKEN,
      useClass: environment.environment !== EnvironmentEnum.LOCAL ? moduleServices.OperatorService :
        moduleServices.OperatorMockService
    },
    {
      provide: moduleServices.GIT_ENV_VARIABLES_SERVICE_TOKEN,
      useClass: moduleServices.GitEnvVariablesService
    },
    {
      provide: moduleServices.API_STATUS_SERVICE_TOKEN,
      useClass: moduleServices.ApiStatusService
    },
  ],
  exports: [
    moduleComponents.ComingSoonComponent,
    moduleComponents.ConfirmationDialogComponent,
    moduleComponents.WarningDialogComponent,
    moduleComponents.PdfComponent,
    moduleComponents.TermsAndConditionsComponent
  ]
})
export class CoreModule {
}
