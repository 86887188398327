import { Injectable, Inject } from '@angular/core';

import * as contracts from './contracts/operator';

import * as configServices from '../config/contracts';
import { ConfigKeysEnum } from '../config/contracts';
import { response as getUserAgreementResponse } from './mock-data/get-user-agreement.response';
import { Observable, of } from 'rxjs';

@Injectable()
export class OperatorMockService implements contracts.IOperatorService {
  eulaPdfUrl: string;
  operatorEulaPdfUrl: string;

  constructor(@Inject(configServices.SERVICE_TOKEN) private configService: configServices.IConfigService) {
    this.eulaPdfUrl = (this.configService.get(ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT) || '') + '/user/eula';
    this.operatorEulaPdfUrl = (this.configService.get(ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT) || '') + '/operator/eula';
  }

  acceptUserAgreement(): Observable<void> {
    return of();
  }

  acceptOperatorAgreement(): Observable<void> {
    return of();
  }

  getUserAgreement(): Observable<contracts.GetUserAgreementResponse> {
    const response = new contracts.GetUserAgreementResponse();
    response.userEulaAccepted = getUserAgreementResponse.data.userEulaAccepted;
    response.superuser = getUserAgreementResponse.data.superuser;
    response.operatorEulaAccepted = getUserAgreementResponse.data.operatorEulaAccepted;

    return of(response);
  }

  getAgreementPdf(request: contracts.GetAgreementPdfRequest): Observable<Blob> {
    const response = new Blob();

    return of(response);
  }

  downloadAgreementPdf(request: contracts.DownloadAgreementPdfRequest): Observable<contracts.DownloadUserAgreementPdfResponse> {
    const response = new contracts.DownloadUserAgreementPdfResponse();

    return of(response);
  }

}
