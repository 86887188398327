import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as dataContracts from '../services/operator/contracts/operator';
import * as sharedTypes from '../../shared/types';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UserAgreementGuard implements CanActivate {
  constructor(
    @Inject(dataContracts.SERVICE_TOKEN)
    private operatorService: dataContracts.IOperatorService,
    private router: Router,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN)
    private notificationService: sharedTypes.INotificationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.operatorService.getUserAgreement()
      .pipe(
        switchMap(serviceResponse => {
          if (serviceResponse.userEulaAccepted && serviceResponse.operatorEulaAccepted) {
            return of(true);
          }

          if (!serviceResponse.superuser && !serviceResponse.operatorEulaAccepted) {
            /** Need timeout here, otherwise notificationService fails silently, it uses setTimeout during its
             * initialization */
            setTimeout(() => {
              this.notificationService.show('Account Owner must first accept the EULA before continuing.',
                sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Danger,
                sharedTypes.NotificationPosition.TopRight);
            }, 0);
            return of(false);
          }

          if (serviceResponse.superuser && !serviceResponse.operatorEulaAccepted) {
            this.router.navigate(['/user-agreement'], {
              queryParams: {'return-url': state.url}
            });
          } else if (!serviceResponse.userEulaAccepted) {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
              this.router.navigate(['/user-agreement'], {
                queryParams: {'return-url': state.url}
              }));
          }

          return of(false);
        })
      );
  }
}
