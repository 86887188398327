import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Amenity Collection] Load';
export const LOAD_SUCCESS = '[RM Amenity Collection] Load Success';
export const LOAD_FAIL = '[RM Amenity Collection] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.AircraftAmenity>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
