import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import * as dataContracts from '../../services/operator/contracts/operator';
import * as models from '../../models';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'user-agreement-page',
  templateUrl: './user-agreement-page.component.html'
})
export class UserAgreementPageComponent implements OnInit {
  userAgreement: dataContracts.GetUserAgreementResponse;
  agreement: models.Agreement;
  form: FormGroup;
  private returnUrl: string;
  agreementType: string = null;
  agreementTypeEnum = models.AgreementType;
  agreementStatusMessage = 'Loading...';

  constructor(@Inject(dataContracts.SERVICE_TOKEN)
              private operatorService: dataContracts.IOperatorService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      agreementConfirmation: [false, Validators.pattern('true')]
    });
  }

  async ngOnInit(): Promise<void> {
    const userAgreement = await this.operatorService.getUserAgreement().pipe(first()).toPromise();
    if (userAgreement.operatorEulaAccepted && userAgreement.userEulaAccepted) {
      this.agreementStatusMessage = 'User Agreement was accepted';
    } else if (!userAgreement.superuser && !userAgreement.operatorEulaAccepted) {
      this.agreementStatusMessage = 'Account Owner must first accept the EULA before continuing.';
    } else if (userAgreement.superuser && !userAgreement.operatorEulaAccepted) {
      this.agreementStatusMessage = null;
      this.agreementType = models.AgreementType.OPERATOR_EULA;
    } else if (!userAgreement.userEulaAccepted) {
      this.agreementStatusMessage = null;
      this.agreementType = models.AgreementType.USER_EULA;
    }

    this.agreement = new models.Agreement();
    if (this.agreementType === models.AgreementType.OPERATOR_EULA) {
      this.agreement.documentUri = `${this.operatorService.operatorEulaPdfUrl}/${userAgreement.operatorUuid}`;
    } else {
      this.agreement.documentUri = this.operatorService.eulaPdfUrl;
    }
    this.agreement.type = this.agreementType;
    
    this.returnUrl = this.route.snapshot.queryParams['return-url'] || '/';
    this.userAgreement = userAgreement;
  }

  submit(): void {
    if (!this.form.valid === true) {
      return;
    }
    let acceptObservable: Observable<void>;
    if (this.agreementType === models.AgreementType.OPERATOR_EULA) {
      const request = new dataContracts.AcceptOperatorAgreementRequest();
      request.operatorUuid =this.userAgreement.operatorUuid;
      acceptObservable = this.operatorService.acceptOperatorAgreement(request);
    } else {
      acceptObservable = this.operatorService.acceptUserAgreement();
    }

    acceptObservable.pipe(
      take(1),
      map(() => {
        this.router.navigateByUrl(this.returnUrl);
      }),
    ).subscribe();
  }
}
