import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as dataContracts from '../services/operator/contracts/operator';

import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class UserAgreementAcceptedGuard implements CanActivate {

  constructor(
    public router: Router,
    @Inject(dataContracts.SERVICE_TOKEN)
    private operatorService: dataContracts.IOperatorService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const returnUrl = route.queryParams['return-url'];

    if (!returnUrl) {
        return of(true);
    }

    return this.operatorService.getUserAgreement()
      .pipe(
        take(1),
        switchMap((response: dataContracts.GetUserAgreementResponse) => {
          if (response.userEulaAccepted && response.operatorEulaAccepted) {
            this.router.navigate([returnUrl]);
            return of(false);
          }
          return of(true);
        })
      );

  }
}
