<h5>This is a legal agreement between you and XO Global LLC.</h5>
<div>
  <span><i class="fa fa-file-pdf-o"></i><a href="javascript:void(0)"
       (click)="downloadPdf()"
       class="m-l-10">Download PDF</a></span>
</div>

<div *ngIf="!showAgreement">
  <spinner class="full-height"
           [xo]="true"></spinner>
</div>
<div *ngIf="showAgreement">
  <div class="user-agreement m-t-15"
       [innerHTML]="innerHtml()"></div>
</div>
