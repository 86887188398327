import * as models from '../../../models';
import * as _ from 'lodash';
import * as moment from 'moment';

export class UserMapper {
  private static instance: UserMapper = new UserMapper();

  static Instance(): UserMapper {
    return UserMapper.instance;
  }

  userDtoToUser(source: any): models.User {
    const user = new models.User();
    user.userId = source.userId;
    user.emailAddress = source.emailAddress;
    user.firstName = source.firstName;
    user.lastName = source.lastName;
    user.userEulaAccepted = source.userEulaAccepted

    if (source.accounts) {
      user.accounts = source.accounts.map((item) => this.userAccountDtoToUserAccount(item));
    }
    
    return user;
  }

  userAccountDtoToUserAccount(item: any): models.UserAccount {
    const account = new models.UserAccount();
    account.superUser = item.superUser;

    if (item.operator) {
      account.operator = this.operatorDtoToOperator(item.operator);
    }

    return account;
  }

  operatorDtoToOperator(source: any): models.Operator {
    const operator = new models.Operator();
    operator.id = source.operatorUuid;
    operator.name = source.name;
    operator.legalName = source.legalName;
    operator.eulaAccepted = source.eulaAccepted;
    operator.prospectusApproved = source.prospectus.approved;
    return operator;
  }


}
