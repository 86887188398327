import {Apollo, gql} from 'apollo-angular';
import { Injectable, Inject } from '@angular/core';
import * as contracts from './contracts/operator';
import * as configServices from '../config/contracts';
import { ConfigKeysEnum } from '../config/contracts';
import { Observable } from 'rxjs/Observable';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as coreReducers from '../../reducers';
import * as userActions from '../../actions/user';
import * as sharedTypes from '../../../shared/types';
import * as models from '../../models';
import {
  AcceptOperatorAgreementMutation,
  AcceptUserAgreementMutation
} from '../../../shared/graphql-types';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class OperatorService implements contracts.IOperatorService {
  eulaPdfUrl: string;
  operatorEulaPdfUrl: string;

  constructor(private http: HttpClient,
              @Inject(configServices.SERVICE_TOKEN) private configService: configServices.IConfigService,
              private store$: Store<any>,
              private apollo: Apollo,
              @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN) private notificationService: sharedTypes.INotificationService) {
    this.eulaPdfUrl = (this.configService.get(ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT) || '') + '/user/eula';
    this.operatorEulaPdfUrl = (this.configService.get(ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT) || '') + '/operator/eula';
  }

  acceptUserAgreement(): Observable<void> {
    return this.apollo.mutate<AcceptUserAgreementMutation>({
      mutation: gql(`
          mutation acceptUserAgreement {
            acceptUserAgreement {
              eulaAccepted
            }
          }`),
    }).pipe(
      withLatestFrom(
        this.store$.pipe(select(coreReducers.getUserUser)),
        this.store$.pipe(select(coreReducers.getUserAccount))
      ),
      map(([serviceResponse, userData, account]) => {
        const user: models.User = _.cloneDeep(userData);
        user.userEulaAccepted = serviceResponse.data.acceptUserAgreement.eulaAccepted;
        this.store$.dispatch(new userActions.LoadSuccessAction({user, account}));
        this.notificationService.show('User Agreement was accepted successfully',
          sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Success,
          sharedTypes.NotificationPosition.TopRight);
      }),
      catchError(() => {
        this.notificationService.show('Something went wrong',
          sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Danger,
          sharedTypes.NotificationPosition.TopRight);
        return of(null);
      })
    );
  }

  acceptOperatorAgreement(request: contracts.AcceptOperatorAgreementRequest): Observable<void> {
    return this.apollo.mutate<AcceptOperatorAgreementMutation>({
      mutation: gql(`
          mutation acceptOperatorAgreement($operatorUuid: String!) {
            acceptOperatorAgreement(operatorUuid: $operatorUuid) {
              eulaAccepted
            }
          }`
        ), 
        variables: {
          operatorUuid: request.operatorUuid
        }
    }).pipe(
      withLatestFrom(
        this.store$.pipe(select(coreReducers.getUserUser)),
        this.store$.pipe(select(coreReducers.getUserAccount))
      ),
      map(([serviceResponse, user, accountData]) => {
        const account: models.UserAccount = _.cloneDeep(accountData);
        account.operator.eulaAccepted = serviceResponse.data.acceptOperatorAgreement.eulaAccepted;
        this.store$.dispatch(new userActions.LoadSuccessAction({user, account}));
        this.notificationService.show('Operator Agreement was accepted successfully',
          sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Success,
          sharedTypes.NotificationPosition.TopRight);
      }),
      catchError(() => {
        this.notificationService.show('Something went wrong',
          sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Danger,
          sharedTypes.NotificationPosition.TopRight);
        return of(null);
      })
    );
  }

  getUserAgreement(): Observable<contracts.GetUserAgreementResponse> {
    return combineLatest([
      this.store$.pipe(select(coreReducers.getUserUser)),
      this.store$.pipe(select(coreReducers.getUserAccount)),
    ]).pipe(
      map(([user, account]) => {
        const response = new contracts.GetUserAgreementResponse();
        response.userEulaAccepted = user.userEulaAccepted;
        response.operatorEulaAccepted = account.operator.eulaAccepted;
        response.operatorUuid = account.operator.id;
        response.superuser = account.superUser;
        return response;
      }));
  }

  getAgreementPdf(request: contracts.GetAgreementPdfRequest): Observable<Blob> {
    const requestUrl = `${request.url}`;
    return this.http.get(requestUrl, { responseType: 'blob' });
  }

  downloadAgreementPdf(request: contracts.DownloadAgreementPdfRequest): Observable<contracts.DownloadUserAgreementPdfResponse> {
    const requestUrl = `${request.url}`;
    return this.http
      .get(requestUrl, { responseType: 'blob' })
      .pipe(
        map(res => {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          const url = window.URL.createObjectURL(res);
          a.href = url;
          a.download = `XO-Operator-Portal-Agreement.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          const resp = new contracts.DownloadUserAgreementPdfResponse();
          return resp;
        })
      );
  }
}
