import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import * as moduleComponents from './components';
import * as moduleDirectives from './directives';
import * as modulePipes from './pipes';

import * as moduleServices from './services';

import { PagesModule } from '../../assets/pages/components/pages.module';
import { pgTabsModule } from '../../assets/pages/components/tabs/tabs.module';

import { EffectsModule } from '@ngrx/effects';
import * as moduleEffects from './effects';
import * as airportContracts from './services/airport/contracts';

@NgModule({
  declarations: [
    moduleComponents.BrowserNotSupportedComponent,
    moduleComponents.DefaultLayoutComponent,
    moduleComponents.HeaderComponent,
    moduleComponents.InternalErrorComponent,
    moduleComponents.PageSummaryComponent,
    moduleComponents.PaginationComponent,
    moduleComponents.SidebarComponent,
    moduleComponents.SpinnerComponent,
    moduleComponents.SystemComponent,
    moduleComponents.VersionHistoryComponent,
    moduleComponents.WaitProgressComponent,
    moduleComponents.WireframeComponent,
    moduleComponents.SidepanelComponent,
    moduleComponents.ViewSwitchComponent,
    moduleDirectives.PopoverContentComponent,
    moduleDirectives.ValidationTooltipDirective,
    moduleDirectives.ClipboardDirective,
    moduleDirectives.InfiniteScrollDirective,
    moduleDirectives.CustomGridBindingDirective,
    moduleDirectives.FixedHeaderDirective,
    modulePipes.EnumKeyValuePipe,
    modulePipes.EnvironmentPipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.EnumToArrayPipe,
    modulePipes.MinutesToTimePipe,
    modulePipes.ViewModePipe,
    modulePipes.SnakeCaseToTitlePipe,
  ],
  exports: [
    moduleComponents.BrowserNotSupportedComponent,
    moduleComponents.DefaultLayoutComponent,
    moduleComponents.HeaderComponent,
    moduleComponents.InternalErrorComponent,
    moduleComponents.PageSummaryComponent,
    moduleComponents.PaginationComponent,
    moduleComponents.VersionHistoryComponent,
    moduleComponents.SidebarComponent,
    moduleComponents.SpinnerComponent,
    moduleComponents.SystemComponent,
    moduleComponents.WaitProgressComponent,
    moduleComponents.WireframeComponent,
    moduleComponents.SidepanelComponent,
    moduleComponents.ViewSwitchComponent,
    moduleDirectives.PopoverContentComponent,
    moduleDirectives.ValidationTooltipDirective,
    moduleDirectives.ClipboardDirective,
    moduleDirectives.InfiniteScrollDirective,
    moduleDirectives.CustomGridBindingDirective,
    moduleDirectives.FixedHeaderDirective,
    modulePipes.EnumKeyValuePipe,
    modulePipes.EnvironmentPipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.EnumToArrayPipe,
    modulePipes.MinutesToTimePipe,
    modulePipes.ViewModePipe,
    modulePipes.SnakeCaseToTitlePipe,
  ],
  imports: [
    BsDropdownModule.forRoot(),
    CommonModule,
    PagesModule,
    pgTabsModule,
    RouterModule,
    TooltipModule,
    EffectsModule.forFeature([
      moduleEffects.AirportEffects
    ])
  ],
  providers: [
    modulePipes.EnumKeyValuePipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.MinutesToTimePipe,
    modulePipes.ViewModePipe,
    moduleServices.ErrorWebServiceLoggerBridge,
    moduleServices.NotificationService,
    moduleServices.ClipboardService,
    moduleServices.AnalyticsService,
    moduleServices.BaseLocalStorageService,
    moduleServices.MemoryStorage,
    {
      provide: airportContracts.SERVICE_TOKEN,
      useClass: moduleServices.AirportService
    }
  ],
  entryComponents: [moduleDirectives.PopoverContentComponent]
})
export class SharedModule {
}
